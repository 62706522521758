const navbarArray = [
    'home',
    'about', 
    'projects', 
    'skills', 
    'experience',
    'contact',
]

export default navbarArray
